import { TrustPilotRating, TrustpilotStyle } from '@finn/ua-vehicle';
import { CTA } from '@finn/ui-components';
import { cn, parseToHtml } from '@finn/ui-utils';

import { StepperCosmicMetadata } from '~/types/UIModules';
import StepperItem from '~/ui-elements/StepperItem/StepperItem';

const tailwindGridCols = {
  1: 'sm:grid-cols-1',
  2: 'sm:grid-cols-2',
  3: 'sm:grid-cols-3',
  4: 'sm:grid-cols-4',
  5: 'sm:grid-cols-5',
  6: 'sm:grid-cols-6',
  7: 'sm:grid-cols-7',
  8: 'sm:grid-cols-8',
  9: 'sm:grid-cols-9',
  10: 'sm:grid-cols-10',
  11: 'sm:grid-cols-11',
  12: 'sm:grid-cols-12',
};

const Stepper = ({ data }: { data: StepperCosmicMetadata }) => {
  /*
    TODO:
    This is a special case for the management cards, which has a different layout
    ideaally we should have a different component for this.
  */
  const isManagementCard = data.html_id === 'management-card';

  return (
    <div className="container" data-testid="stepper">
      <h2 className="mobile-t3-semibold md:web-t3-semibold mb-14">
        {parseToHtml(data.title)}
      </h2>
      <div
        className={cn(
          `grid grid-cols-1 gap-6 gap-y-10 sm:gap-6 lg:gap-y-16`,
          tailwindGridCols[data.columns_per_row.value]
        )}
      >
        {data.items.map((stepperItem) => (
          <div key={stepperItem.id}>
            <StepperItem
              item={stepperItem.metadata}
              isManagementCard={isManagementCard}
            />
          </div>
        ))}
      </div>
      {data.insert_trustpilot_trustelement && (
        <div
          className={cn(
            'pt-10 sm:pt-14',
            data.trust_pilot_with_less_padding && 'pt-10'
          )}
        >
          <TrustPilotRating
            style={TrustpilotStyle.Stepper}
            mobileHeight="150px"
          />
        </div>
      )}

      {data.cta && <CTA data={data.cta.metadata} />}
    </div>
  );
};

export default Stepper;
